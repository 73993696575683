<template>
    <router-view class="white lighten-1" >
    </router-view>
</template>

<script>
export default {
  name: 'PageAttribute',
};
</script>

<style></style>
